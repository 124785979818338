import {BrowserRouter} from "react-router-dom";
import Banner from "./components/Banner";
import About from "./components/About";
import Video from "./components/Video";
import Cartoz from "./components/Cartoz";
import Footer from "./components/Footer";
import {useTranslation} from "react-i18next";

function App() {
    const {t} = useTranslation();

    return (
        <BrowserRouter>
            <div className={"App App_" + t('lang')}>
                <Banner/>
                <div className={'App__content'}>
                    <About/>
                </div>
                <Video/>
                <div className={'App__content'}>
                    <Cartoz/>
                    <Footer/>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;