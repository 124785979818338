import {useTranslation} from "react-i18next";

export const Menu = () => {
    const { t, i18n } = useTranslation();

    function toggleLang() {
        if (t('lang') === 'eng' ) {
            i18n.changeLanguage("it")
        } else {
            i18n.changeLanguage("eng")
        }
    }

    return (
        <nav className={'nav'}
             onClick={() => toggleLang()}>
            <span className={'language-pic'}></span>
            <span className={'nav__language'}>{t('lang')}</span>
            {/*<select className={'nav__select'} defaultValue={"en"}>
                {LANGUAGES.map(({ code, label }) => (
                    <option className={'nav__option'} key={code} value={code}>
                        {label}
                    </option>
                ))}
            </select>*/}
        </nav>
    );
};