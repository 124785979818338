import {useTranslation} from "react-i18next";
import {Menu} from "./Menu";
import Head from "./Head";

export default function Banner() {
    const {t} = useTranslation();

    return (
        <div className={'banner'}>
            <div className={'banner__shade'}></div>
            <div className={'App__content'}>
                <Menu/>
                <Head/>
                <div className={'banner__partners-wrap _mobile'}>
                    <div className={'banner__partners banner__partners_' + t('lang')}></div>
                </div>
                <div className={'logo logo_' + t('lang')}></div>
                <div className={'banner__texts'}>
                    <h2 className={'banner__compositor'}>{t('compositor')}</h2>
                    <h1 className={'banner__compositor-name'}>{t('compositorName')}</h1>
                    <div className={'banner__compositor-wrap banner__compositor-wrap_1'}>
                        <span className={'banner__compositor-subtext'}>{t('compositorSubtext1')}</span>
                    </div>
                    <div className={'banner__compositor-wrap'}>
                        <span className={'banner__compositor-subtext'}>{t('compositorSubtext2')} </span>
                        <span className={'banner__compositor-subtext banner__compositor-subtext_2'}>{t('compositorSubtext3')}</span>
                    </div>
                    <div className={'banner__partners-wrap _desktop'}>
                        <div className={'banner__partners banner__partners_' + t('lang')}></div>
                    </div>
                </div>
                <div className={'about__button-wrap'}>
                    <a target={'_blank'}
                       rel={"noreferrer"}
                       className={'about__button'}
                       href={'https://www.eventbrite.it/e/in-principio-era-lacqua-tickets-915123106847?aff=oddtdtcreator&_gl=1%2A1juwgdi%2A_up%2AMQ..%2A_ga%2AMTMxNzg4Njk4NS4xNzE4MDA3OTM5%2A_ga_TQVES5V6SH%2AMTcxODAwNzkzOC4xLjEuMTcxODAwODAyNC4wLjAuMA'}>{t('aboutButton')}</a>
                </div>
            </div>
        </div>
    )
}