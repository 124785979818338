import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
    lng: "it",
    fallbackLng: "it",
    supportedLngs: ['it', 'eng'],
    interpolation: {
        escapeValue: false,
    },
    resources: {
        eng: {
            translation: {
                head1_1: "Tuesday,",
                head1_2: "JUNE",
                head1_3: "25th",
                head1_4: "21:00",
                head2_1: "Scuola Grande",
                head2_2: "San Giovanni",
                head2_3: "Evangelista",
                lang: 'eng',
                compositor: 'composer',
                compositorName: 'Nico Cartosio',
                compositorSubtext1: 'Conductor Sergey Luran',
                compositorSubtext2: 'piano — Emanuele Savron,',
                compositorSubtext3: 'violin — Giada Visentin',
                aboutHeadline: 'About this event',
                aboutSubhead: "\"In principio era l'Acqua\"",
                aboutP1: "Nico Cartosio is a contemporary composer whose debut album Melting was released in 2019 and went straight to the Top 20 Billboard Classical.",
                aboutP1_1: "The new symphonic program “In the Beginning was the Water” is a poetic dedication to Venice, \"in whose waters time lives.",
                aboutP2: "Water was the beginning. Even before the act of divine creation. God created heaven and earth, separating them from the watery abyss.  Water is the oldest thing in our world. And it holds the memory of everything. The Venetian Lagoon is a repository of time.",
                aboutP3: "The Scuola Grande San Giovanni Evangelista could not have been a better choice of venue for the symphonic concert. Not only because it is an extraordinary 13th century hall with works by Bellini, Tiepolo and Tintoretto, but also because it is the Gospel of John that begins with the words \"In the Beginning was the Word”.",
                aboutButton: 'get a ticket',
                cartozName: 'Nico Cartosio',
                cartozP1_1: "Nico Cartosio conceptually aligns all elements of his musical design in every composition, resulting in a symphonic reflection.",
                cartozP1_2: "",
                cartozP1_3: "Conceptualism and rhetoric in music are key characteristics of Nico Cartosio's works, which make his artistic language accessible to a wider audience. It's not a coincidence that his videos have been viewed over 15 million times on YouTube, which is particularly surprising. It's unusual for many pop singers to achieve this kind of result, especially classical composers.",
                cartozP2_1: "Concerto realizzato con il patrocinio del Conservatorio Benedetto Marcello e della Fondazione Ugo e Olga Levi.",
                cartozP2_2: "Musiche ad opera di OFI - L'Orchestra Filarmonica Italiana e il Coro dell'Opera di Parma",
                cartozP3_1: "martedì 25 giugno 2024, ore 21:00",
                cartozP3_2: "Scuola Grande San Giovanni Evangelista di Venezia, nel Salone Capitolare",
                cartozP3_3: "prenotazione obbligatoria entro il 20 giugno 2024",
                youtube: "https://www.youtube.com/embed/czor_6yU1m8?si=sIrWbNXPeVPn80wa&autoplay=1&mute=1",
            },
        },
        it: {
            translation: {
                head1_1: "Martedì",
                head1_2: "25/06",
                head1_3: "ore",
                head1_4: "21:00",
                head2_1: "Scuola Grande",
                head2_2: "San Giovanni",
                head2_3: "Evangelista",
                lang: 'it',
                compositor: 'compositore',
                compositorName: 'Nico Cartosio',
                compositorSubtext1: 'Direttore M° Sergey Luran',
                compositorSubtext2: 'Emanuele Savron — pianoforte, ',
                compositorSubtext3: ' Giada Visentin — violino',
                aboutHeadline: 'About this event',
                aboutSubhead: "In principio era l'Acqua",
                aboutP1: "Il compositore contemporaneo Nico Cartosio, il cui album di debutto è entrato, subito dopo il lancio, nella TOP 20 Billboard Classical, presenta a Venezia il suo nuovo programma “In principio era l'Acqua”. Una dedica poetica a Venezia, “nelle acque della quale vive il tempo”.",
                aboutP1_1: "",
                aboutP2: "L’acqua esiste sin dal principio. Ancora prima dell’atto della creazione divina. Dio creò il cielo e la terra, separandoli dall’abisso dell’acqua. L’acqua è quanto più antico ci sia nel nostro mondo. Conserva in sé memoria di tutto. La Laguna veneta è custode del tempo.",
                aboutP3: "Le composizioni di Nico Cartosio si legano profondamente alla Scuola Grande San Giovanni Evangelista che accoglie nelle proprie sale opere del Bellini, Tiepolo e Tintoretto; ma il programma musicale si ispira soprattutto alla storia e alla tradizione del Vangelo secondo Giovanni dal quale viene ripreso il titolo del concerto.",
                aboutButton: 'PRENOTA IL TUO BIGLIETTO',
                cartozName: 'Nico Cartosio',
                cartozP1_1: "Nico Cartosio armonizza concettualmente ogni singolo elemento del proprio disegno musicale in ogni singola composizione, rappresentante ciascuna una riflessione sinfonica sul tema del tempo. Il concettualismo e la retorica in musica sono caratteristiche cardine delle opere di Nico Cartosio, che rendono il suo linguaggio artistico accessibile ad un vasto pubblico. Non a caso i suoi video hanno raggiunto più di 15 milioni di visualizzazioni su YouTube, risultato sorprendente, in particolare se contestualizzato nell’ambito del repertorio classico.",
                cartozP1_2: "",
                cartozP1_3: "",
                cartozP2_1: "Concerto realizzato con il patrocinio del Conservatorio Benedetto Marcello e della Fondazione Ugo e Olga Levi.",
                cartozP2_2: "Musiche ad opera di OFI - L'Orchestra Filarmonica Italiana e il Coro dell'Opera di Parma",
                cartozP3_1: "martedì 25 giugno 2024, ore 21:00",
                cartozP3_2: "Scuola Grande San Giovanni Evangelista di Venezia, nel Salone Capitolare",
                cartozP3_3: "prenotazione obbligatoria entro il 20 giugno 2024",
                youtube: "https://www.youtube.com/embed/qMIXVp3t2mI?si=5U-1n7g50DCscGT7&autoplay=1&mute=1",
            },
        },
    },
});

export default i18n;